import React, { useEffect, useState } from 'react';
import './CreateJob.scss';
import {
  Alert,
  Button,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  fetchJobForEditRequest,
  getPaymentStatus,
  JobTypes,
  PREMIUM_BOOST_PRICE,
  useRequestCallback,
  useSubmitJob,
  usePayJob,
  useUpdateJobRequest,
  PaymentType,
  ONGOING_JOB_POST_FEE,
} from '../../data/jobs';
import CalendlyModal from './components/CalendlyModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GenericModal from '../../components/generic-modal/GenericModal';
import { Routes } from '../../utils/consts';
import MiniteSnackbar from '../../components/snackbar';
import { LoadingButton } from '../../components/LoadingButton';
import PromoButtons from '../../components/promo-buttons';
import { usePostJobWizardLoading } from './store';
import { CircularProgress } from '@mui/material';

interface Props {
  handleBack: () => void;
  job: any;
  serviceFee: number;
}

enum PromoType {
  Boost = 'Boost',
  Free = 'Free',
}

const schema = yup
  .object()
  .shape({
    job_boost_pack: yup.number().min(0).required(),
    isConfirm: yup.boolean().required().oneOf([true]),
  })
  .required();

const getCost = (
  budget: number,
  studentCount: number,
  serviceFee: number,
): number => {
  return budget * (serviceFee / 100) * studentCount;
};

const getVat = (
  budget: number,
  studentCount: number,
  serviceFee: number,
  isOngoing = false,
): number => {
  return (
    (getCost(budget, studentCount, serviceFee) +
      (isOngoing ? ONGOING_JOB_POST_FEE : PREMIUM_BOOST_PRICE)) *
    0.21
  );
};

const getSubTotal = (
  budget: number,
  studentCount: number,
  serviceFee: number,
  isOngoing = false,
): number => {
  return (
    getCost(budget, studentCount, serviceFee) +
    (isOngoing ? ONGOING_JOB_POST_FEE : PREMIUM_BOOST_PRICE)
  );
};

const getTotal = (
  budget: number,
  studentCount: number,
  serviceFee: number,
  isOngoing = false,
): number => {
  return (
    getCost(budget, studentCount, serviceFee) +
    getVat(budget, studentCount, serviceFee, isOngoing) +
    (isOngoing ? ONGOING_JOB_POST_FEE : PREMIUM_BOOST_PRICE)
  );
};

const getVatWithoutBoost = (
  budget: number,
  studentCount: number,
  serviceFee: number,
): number => {
  return getCost(budget, studentCount, serviceFee) * 0.21;
};

const getTotalWithoutBoost = (
  budget: number,
  studentCount: number,
  serviceFee: number,
): number => {
  return getCost(budget, studentCount, serviceFee) * 1.21;
};

const getPaymentType = (promoType: string) => {
  return promoType === PromoType.Boost
    ? PaymentType.PostingPlusBoostPackFee
    : PaymentType.PostingFee;
};

const getBoosterPack = (
  promoType,
  jobBudget,
  students_needed,
  isStudents,
  serviceFee,
) => {
  let jobBoostPack: number;
  const studentNeeds = isStudents ? 1 : students_needed;
  const budget = isStudents ? 0 : jobBudget;

  if (promoType === PromoType.Boost) {
    jobBoostPack = getTotal(budget, studentNeeds, serviceFee);
  } else {
    jobBoostPack = getTotalWithoutBoost(budget, studentNeeds, serviceFee);
  }

  return jobBoostPack;
};

const SkeletonForm = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
    </Stack>
  );
};

export const ReviewStep = ({ handleBack, job, serviceFee }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_review',
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [jobInReview, setJobInReview] = useState(job);
  const loading = usePostJobWizardLoading((state) => state.loading);
  const setLoading = usePostJobWizardLoading((state) => state.setLoading);
  const navigate = useNavigate();

  const CheckboxLabel = () => {
    return (
      <span className="user-agreement-text">
        {t('agreement')}{' '}
        <a
          href="https://www.minite.works/terms-of-use/terms-of-use-companies"
          target="_blank"
          rel="noopener noreferrer"
          className="learn-more-link"
        >
          {t('learn_more')}.
        </a>
      </span>
    );
  };

  const [promoType, setPromoType] = useState(PromoType.Boost);
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const [calendlyModalViewed, setCalendlyModalViewed] = useState(false);
  const { mutateAsync: requestCallback } = useRequestCallback();
  const { mutateAsync: updateJobRequest } = useUpdateJobRequest();
  const { mutateAsync: payJob } = usePayJob();
  const { mutateAsync: submitJob } = useSubmitJob();
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const { jobUid } = useParams();
  const [paidStatus, setPaidStatus] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isPaid = path[path.length - 1] === 'paid';

  const jobType =
    jobInReview?.job_type === JobTypes.ONGOING ||
    jobInReview?.job_type === JobTypes.PREMIUM;

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    setValue,
  } = useForm<{
    job_boost_pack: number;
    isConfirm: boolean;
  }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      job_boost_pack: 0,
      isConfirm: false,
    },
    reValidateMode: 'onChange',
  });

  const boostPack = (boost) => {
    return Number(boost) ? PromoType.Boost : PromoType.Free;
  };

  useEffect(() => {
    if (isPaid) {
      setLoading(true);

      setValue('isConfirm', true, { shouldValidate: true });
      (async () => {
        try {
          setLoading(true);
          const response = await fetchJobForEditRequest(jobUid);
          setJobInReview(response.job);

          try {
            setLoading(true);
            const responseStatus = await getPaymentStatus(
              jobUid,
              getPaymentType(boostPack(response?.job.job_boost_pack)),
            );

            setPaidStatus(responseStatus);

            if (responseStatus !== 'paid') {
              setIsOpenModal(true);
            }
          } catch (error) {
            setErrorMessage('Failed to get payment status!');
          } finally {
            setLoading(false);
          }
        } catch (error) {
          setErrorMessage('Failed to retrieve job!');
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (isPaid) {
      setPromoType(boostPack(jobInReview?.job_boost_pack));
    }
  }, [jobInReview]);

  const redirectToMatch = () => {
    navigate(Routes.BrowseJobsRoute + `/${jobInReview?.job_uid}/matches`, {
      replace: true,
    });
  };

  const toggleCalendly = () => {
    setIsOpenModal(false);

    setCalendlyModalOpen(!calendlyModalOpen);
    setCalendlyModalViewed(true);
  };

  const handleSubmitJob = async () => {
    try {
      setLoading(true);
      await submitJob({ jobUid: jobInReview?.job_uid });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      await redirectToMatch();
    } catch (error) {
      setErrorMessage('Failed to submit job!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { job_uid, job_type } = jobInReview || {};

    if (paidStatus === 'paid' && job_uid === jobUid) {
      if (
        !calendlyModalViewed &&
        (job_type === JobTypes.ONGOING || job_type === JobTypes.PREMIUM)
      ) {
        toggleCalendly();
        return;
      }

      handleSubmitJob();
    }
  }, [paidStatus, jobInReview]);

  const onSubmit = async () => {
    const isBoost = promoType === PromoType.Boost;

    const { budget, students_needed, job_type, job_uid, job_boost_pack } =
      jobInReview || {};

    const jobBoostPack = getBoosterPack(
      promoType,
      budget,
      students_needed,
      jobType,
      serviceFee,
    );

    if (
      !calendlyModalViewed &&
      !jobBoostPack &&
      (job_type === JobTypes.ONGOING || job_type === JobTypes.PREMIUM)
    ) {
      toggleCalendly();
      return;
    }

    if (!isPaid) {
      const payload = {
        job: {
          job_boost_pack:
            +job_boost_pack && isBoost
              ? +job_boost_pack
              : isBoost
              ? job_type === JobTypes.ONGOING
                ? ONGOING_JOB_POST_FEE
                : PREMIUM_BOOST_PRICE
              : 0,
        },
      };
      try {
        setLoading(true);
        await updateJobRequest({ job_uid: job_uid, body: payload });
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error.message || error?.message?.toString(),
        );
      } finally {
        setLoading(false);
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (!!jobBoostPack) {
      try {
        setLoading(true);
        const response = await payJob({
          jobUid: job_uid,
          body: {
            job_boost_pack: isBoost
              ? job_type === JobTypes.ONGOING
                ? 99
                : 50
              : 0,
            payment_type: getPaymentType(promoType),
          },
        });

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        window.open(response.data.checkout_url, '_self');
      } catch (error) {
        setErrorMessage('Failed to generate payment link for job!');
      } finally {
        setLoading(false);
      }
    } else {
      handleSubmitJob();
    }
  };

  const handleClick = (type: PromoType) => {
    setPromoType(type);
  };

  const handleCallbackRequest = (inTwoHours = false) => {
    requestCallback({ jobUid: jobInReview?.job_uid, inTwoHours }).then(() => {
      setCalendlyModalOpen(false);
      setCalendlyModalViewed(true);
    });
  };

  return (
    <>
      {loading ? (
        <>
          {!isPaid ? (
            <SkeletonForm />
          ) : (
            <div>
              <CircularProgress />
              <p>Loading, please do not close this screen</p>
            </div>
          )}
        </>
      ) : (
        <>
          <section className="review-payment-section">
            <form className="review-payment-form">
              {jobInReview?.job_type !== JobTypes.ONGOING && (
                <p className="promo-check-text">{t('promo_pack.title')}</p>
              )}
              <PromoButtons
                onClick={handleClick}
                jobType={jobInReview?.job_type}
              />

              <p
                className="new-job-title"
                style={{ paddingBottom: '0px', margin: '3px' }}
              >
                {t('job_listing.title')}
              </p>

              <div className="payment-section">
                <div
                  className="new-job-container"
                  style={{ paddingBottom: '0px' }}
                >
                  <div className="new-job-col1">
                    <p className="new-job-small-title">
                      {t('job_listing.new_job')} {jobInReview?.job_title}
                    </p>
                    <p style={{ margin: '0px' }}>
                      {jobInReview?.job_type === JobTypes.ONE_TIME
                        ? `${serviceFee}% ${t('job_listing.posting_fee')} €${
                            jobInReview?.budget
                          }`
                        : ``}
                    </p>
                    {!jobType && (
                      <p>Hiring {jobInReview?.students_needed} High-Flyers </p>
                    )}
                  </div>

                  {!jobType && (
                    <div className="new-job-col2">
                      <br />
                      <br />
                      <p>
                        €
                        {(
                          jobInReview?.budget *
                          (serviceFee / 100) *
                          jobInReview?.students_needed
                        ).toLocaleString('nl', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  )}
                </div>

                {promoType === PromoType.Boost &&
                  jobInReview?.job_type === JobTypes.ONGOING && (
                    <div className="new-job-container">
                      <div className="new-job-col1">
                        <p>Job posting fee</p>
                      </div>

                      <div className="new-job-col2">
                        <p>
                          €
                          {ONGOING_JOB_POST_FEE.toLocaleString('nl', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                    </div>
                  )}

                {promoType === PromoType.Boost &&
                  jobInReview?.job_type !== JobTypes.ONGOING && (
                    <div className="new-job-container">
                      <div className="new-job-col1">
                        <p>{t('job_listing.social_boost')}</p>
                      </div>

                      <div className="new-job-col2">
                        <p>
                          €
                          {PREMIUM_BOOST_PRICE.toLocaleString('nl', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                    </div>
                  )}
              </div>

              <div className="total-section">
                <div className="new-job-total">
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {/* TOTAL */}
                        <TableRow
                          sx={{
                            borderBottomWidth: '1px',
                            borderBottomColor: '#c7d2e2',
                            borderBottomStyle: 'solid',
                          }}
                        >
                          <TableCell
                            className="table-cells total-cell left-cells"
                            align="right"
                          >
                            {t('job_listing.total')}
                          </TableCell>
                          <TableCell
                            className="table-cells total-cell"
                            align="right"
                          >
                            {jobInReview?.job_type === JobTypes.ONGOING &&
                              '€' +
                                getSubTotal(
                                  0,
                                  1,
                                  serviceFee,
                                  true,
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            {jobType && promoType === PromoType.Free && '€-'}
                            {jobType &&
                              promoType === PromoType.Boost &&
                              jobInReview?.job_type !== JobTypes.ONGOING &&
                              '€' +
                                getSubTotal(0, 1, serviceFee).toLocaleString(
                                  'nl',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}
                            {!jobType &&
                              jobInReview?.job_type !== JobTypes.ONGOING &&
                              promoType === PromoType.Boost &&
                              '€' +
                                (
                                  getCost(
                                    jobInReview?.budget,
                                    jobInReview?.students_needed,
                                    serviceFee,
                                  ) + PREMIUM_BOOST_PRICE
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            {promoType === PromoType.Free &&
                              !jobType &&
                              '€' +
                                getCost(
                                  jobInReview?.budget,
                                  jobInReview?.students_needed,
                                  serviceFee,
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </TableCell>
                        </TableRow>

                        {/* VAT */}
                        <TableRow>
                          <TableCell
                            sx={{ borderBottom: 'none' }}
                            className="table-cells left-cells"
                            align="right"
                          >
                            21% {t('job_listing.vat')}
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: 'none' }}
                            className="table-cells"
                            align="right"
                          >
                            {jobInReview?.job_type === JobTypes.ONGOING &&
                              '€' +
                                getVat(0, 1, serviceFee, true).toLocaleString(
                                  'nl',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}
                            {jobType && promoType === PromoType.Free && '€-'}
                            {jobType &&
                              jobInReview?.job_type !== JobTypes.ONGOING &&
                              promoType === PromoType.Boost &&
                              '€' +
                                getVat(0, 1, serviceFee).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            {!jobType &&
                              jobInReview?.job_type !== JobTypes.ONGOING &&
                              promoType === PromoType.Boost &&
                              '€' +
                                getVat(
                                  jobInReview?.budget,
                                  jobInReview?.students_needed,
                                  serviceFee,
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            {promoType === PromoType.Free &&
                              !jobType &&
                              '€' +
                                getVatWithoutBoost(
                                  jobInReview?.budget,
                                  jobInReview?.students_needed,
                                  serviceFee,
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </TableCell>
                        </TableRow>

                        {/* Total incl VAT */}
                        <TableRow>
                          <TableCell
                            sx={{ borderBottom: 'none' }}
                            className="table-cells total-cell left-cells"
                            align="right"
                          >
                            {t('job_listing.total_incl_vat')}
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: 'none' }}
                            className="table-cells total-cell"
                            align="right"
                          >
                            {jobInReview?.job_type === JobTypes.ONGOING &&
                              promoType === PromoType.Boost &&
                              '€' +
                                getTotal(0, 1, serviceFee, true).toLocaleString(
                                  'nl',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}
                            {jobType && promoType === PromoType.Free && '€-'}
                            {jobType &&
                              jobInReview?.job_type !== JobTypes.ONGOING &&
                              promoType === PromoType.Boost &&
                              '€' +
                                getTotal(0, 1, serviceFee).toLocaleString(
                                  'nl',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}
                            {!jobType &&
                              jobInReview?.job_type !== JobTypes.ONGOING &&
                              promoType === PromoType.Boost &&
                              '€' +
                                getTotal(
                                  jobInReview?.budget,
                                  jobInReview?.students_needed,
                                  serviceFee,
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            {promoType === PromoType.Free &&
                              !jobType &&
                              '€' +
                                getTotalWithoutBoost(
                                  jobInReview?.budget,
                                  jobInReview?.students_needed,
                                  serviceFee,
                                ).toLocaleString('nl', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </form>
          </section>
          {job?.job_type === JobTypes.ONE_TIME && (
            <Alert severity="success">{t('otj_guarantee')}</Alert>
          )}
          <div className="user-agreement">
            <Controller
              name="isConfirm"
              control={control}
              render={({ field, field: { onChange, value } }) => (
                <FormControlLabel
                  {...field}
                  label={<CheckboxLabel />}
                  data-testid="user-agreement"
                  control={
                    <Checkbox
                      id="accept-checkbox"
                      onChange={onChange}
                      className={!!errors.isConfirm ? 'error' : ''}
                      checked={value}
                      sx={{
                        [`&.${checkboxClasses.checked}`]: {
                          color: '#DEB900',
                        },
                      }}
                    />
                  }
                />
              )}
            />
          </div>
          <div className="createjob-content-buttons">
            <Button
              variant="text"
              onClick={handleBack}
              data-testid="previous-step-post-job-review"
            >
              {t('back')}
            </Button>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
              className="pay-button"
              buttonText={
                paidStatus !== 'paid' &&
                getBoosterPack(
                  promoType,
                  jobInReview?.budget,
                  jobInReview?.students_needed,
                  jobType,
                  serviceFee,
                )
                  ? t('pay_for_job')
                  : t('submit')
              }
              isLoading={loading}
              confirmButtonTestId="submit-post-job"
            />
          </div>

          <CalendlyModal
            open={calendlyModalOpen}
            onClose={() => {
              toggleCalendly();
              handleSubmitJob();
            }}
            onScheduleCall={handleCallbackRequest}
          />

          <GenericModal
            open={isOpenModal}
            title={t('payment_status')}
            content={t('could_not_process_your_payment')}
            confirmButtonText={t('continue')}
            confirmButtonTestId="close-couldnt-process-payment"
            handleConfirm={() => {
              setIsOpenModal(false);
              setPaidStatus('');
            }}
          />

          <MiniteSnackbar
            open={!!errorMessage}
            title={'Error'}
            message={errorMessage}
            autoHideDuration={4000}
            severity={'error'}
            onClose={() => setErrorMessage(null)}
          />
        </>
      )}
    </>
  );
};

import React, { useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './ProposalCompanyView.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  JobData,
  JobStatuses,
  JobTypes,
  StudentTypes,
  updateJobRequest,
} from '../../../data/jobs';
import { ProposalStatuses } from '../index';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { User } from '../../../data/user';
import { generatePath, useNavigate } from 'react-router-dom';
import { Routes } from '../../../utils/consts';
import ProposalConfirmationModal from './ProposalConfirmationModal';
import {
  fetchChatByProposalId,
  ProposalResponse,
  ProposalStudentUserResponse,
  useAcceptProposal,
  useInterviewInviteProposal,
  useRejectProposal,
  useStartChatProposal,
} from '../../../data/proposals';
import ProposalStatusChip from './ProposalStatusChip';
import {
  useAddStudentToTalentPool,
  useFetchTalentPool,
  useRemoveStudentFromTalentPool,
} from '../../../data/talent-pool';
import StarIcon from '@mui/icons-material/Star';
import HireStudentModal, { HireStudentForm } from './HireStudentModal';
import { useTranslation } from 'react-i18next';
import RequestInterviewModal from './RequestInterviewModal';
import StartChatModal from './StartChatModal';
import { numberWithDec } from '../../../utils/numberWithDec';
import { openChatUrl } from '../../../utils/openChat';
import TopTalent from '../../../components/top-talent';
import MiniteSnackbar from '../../../components/snackbar';
import { LoadingButton } from '../../../components/LoadingButton';
import CircularProgressWithLabel from '../../../components/talents/CircularProgressWithLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';

interface Props {
  job: JobData;
  user?: User;
  hiredCount: number;
  interviewsCount: number;
  proposal: ProposalResponse;
  handleDrawerClose?: (event) => void;
  showSnack: (arg: boolean) => void;
}

const getStudentDegree = (
  proposalStudent: ProposalStudentUserResponse,
): string => {
  if (proposalStudent?.education.length > 0) {
    const studentEducation = proposalStudent.education[0];
    return [studentEducation.degree, studentEducation.study]
      .filter((item) => {
        return !!item;
      })
      .join(' ');
  }

  return '';
};

const ProposalCompanyView = ({
  job,
  proposal,
  interviewsCount,
  hiredCount,
  handleDrawerClose,
  showSnack,
}: Props) => {
  const navigate = useNavigate();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);
  const [isHireDialogOpen, setIsHireDialogOpen] = useState(false);
  const [isHireLoading, setIsHireLoading] = useState(false);

  const { data: talentPool, refetch: refreshTalentPool } =
    useFetchTalentPool(true);

  const { mutateAsync: rejectProposal, isLoading: isRejectProposalLoading } =
    useRejectProposal();
  const { mutateAsync: inviteProposal, isLoading: isInviteProposalLoading } =
    useInterviewInviteProposal(proposal?.proposal_uid);
  const { mutateAsync: startChat, isLoading: isStartChatLoading } =
    useStartChatProposal();
  const { mutateAsync: acceptProposal } = useAcceptProposal();
  const { mutateAsync: addStudentToTalentPool } = useAddStudentToTalentPool(
    proposal?.student.student_uid,
  );
  const { mutateAsync: removeStudentFromTalentPool } =
    useRemoveStudentFromTalentPool(proposal?.student.student_uid);

  const existInTalentPool = Boolean(
    talentPool?.find(
      (talent) => talent.student.student_uid === proposal?.student.student_uid,
    ),
  );

  const maxInterviewsReached = interviewsCount >= job.students_needed * 2;
  const maxHiresReached = hiredCount >= job.students_needed;

  const [errorMessage, setErrorMessage] = useState(null);
  const [startChatLoading, setStartChatLoading] = useState(false);

  const redirectToChat = () => {
    openChatUrl(proposal?.proposal_uid);
  };

  const handleStartChatProposal = async () => {
    if (proposal?.proposal_status === ProposalStatuses.PENDING) {
      await startChat(proposal?.proposal_uid);
    }

    setIsChatDialogOpen(false);
    handleDrawerClose(null);
    redirectToChat();
  };

  const handleStartChatForProposal = async () => {
    if (job?.job_type === JobTypes.ONE_TIME) {
      try {
        setStartChatLoading(true);
        await handleStartChatProposal();
      } catch (error) {
        setErrorMessage(
          error.response?.data?.error.message || error.message.toString(),
        );
      } finally {
        setStartChatLoading(false);
      }
    } else {
      setIsChatDialogOpen(true);
    }
  };

  const handleOpenChat = () => {
    if (
      proposal?.proposal_status === ProposalStatuses.SIGNED &&
      job?.job_status === JobStatuses.IN_PROGRESS
    ) {
      redirectToChat();
    } else {
      setStartChatLoading(true);
      fetchChatByProposalId(proposal.proposal_uid)
        .then(() => {
          setStartChatLoading(false);
          redirectToChat();
        })
        .catch(() => handleStartChatForProposal());
    }
  };

  const handleToggleTalentPoolClick = async (event) => {
    event?.preventDefault();
    if (existInTalentPool) {
      await removeStudentFromTalentPool();
    } else {
      await addStudentToTalentPool();
    }
    await refreshTalentPool();
  };

  const handleRejectDialogOpen = () => setIsRejectDialogOpen(true);
  const handleInterviewConfirmationDialogOpen = async () => {
    if (!job.direct_job) {
      setIsInviteDialogOpen(true);
    } else {
      await inviteProposal(null);
      await handleDrawerClose(null);
    }
  };

  const handleHireStudentDialogOpen = () => setIsHireDialogOpen(true);

  const handleRejectProposal = async (reason: string) => {
    const payload = {
      proposalId: proposal?.proposal_uid,
      reason: reason,
    };
    await rejectProposal(payload);
    setIsRejectDialogOpen(false);
    handleDrawerClose(null);
  };

  const handleInterviewInviteProposal = async (payload) => {
    const data = {
      start_date_time: dayjs(payload?.date_one)
        .utc(true)
        .format('DD MMMM YYYY H:mm A'),
      end_date_time: dayjs(payload?.date_two)
        .utc(true)
        .format('DD MMMM YYYY H:mm A'),
    };

    try {
      await inviteProposal(data);
      await setIsInviteDialogOpen(false);
      await handleDrawerClose(null);
      showSnack(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAgreementNavigate = () => {
    navigate(Routes.AgreementRoute + proposal?.proposal_uid);
  };

  const handleTimesheetNavigate = () => {
    const path = generatePath(Routes.TimesheetStudentRoute, {
      jobUid: job?.job_uid,
      studentUid: proposal?.student?.student_uid,
    });
    navigate(path, {
      replace: true,
    });
  };

  const closeHireModal = () => {
    setIsHireDialogOpen(false);
    setIsHireLoading(false);
  };

  const handleHireStudent = (payload: HireStudentForm): void => {
    if (job?.job_uid) {
      setIsHireLoading(true);
      updateJobRequest(job.job_uid, { job: payload })
        .then(() => {
          acceptProposal(proposal.proposal_uid)
            .then(() => {
              handleAgreementNavigate();
              closeHireModal();
            })
            .catch(() => {
              closeHireModal();
            });
        })
        .catch(() => {
          closeHireModal();
        });
    }
  };

  const handleHireStudentForDirectJob = (): void => {
    if (job?.job_uid) {
      setIsHireLoading(true);
      acceptProposal(proposal.proposal_uid)
        .then(() => {
          closeHireModal();
          handleDrawerClose(null);
        })
        .catch(async () => {
          closeHireModal();
          handleDrawerClose(null);
        });
    }
  };

  const handleViewProfile = () => {
    handleDrawerClose(null);
    const pathToProfile = generatePath(Routes.StudentJobProfileRoute, {
      jobUid: job?.job_uid,
      profileId: proposal?.student.student_uid,
    });
    window.open(pathToProfile, '_blank');
  };

  const { t } = useTranslation();

  return (
    <>
      <section className="proposal-company-container">
        <div className="proposal-company-header" style={{ zIndex: 1000 }}>
          <div className="title-container">
            <p className="title">High-Flyer proposal</p>
          </div>
          <div className="close-icon-container">
            <CloseOutlinedIcon
              className="close-drawer-icon"
              onClick={handleDrawerClose}
            />
          </div>
        </div>
        <div className="proposal-company-content">
          <div className="student-header">
            <div className="student-titles">
              <div className="student-study-container">
                <div className="student-study-titles">
                  <img
                    src="/images/student-avatars/7.png"
                    alt="Student Avatar"
                    className="student-avatar-image"
                  />

                  <div className="student-study">
                    <span className="study-title">
                      {getStudentDegree(proposal?.student)}
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '80px',
                      }}
                    >
                      <div>
                        <div className="rating">
                          <StarIcon className="favourite-rate-star" />
                          <p className="rate-number">
                            {numberWithDec(proposal?.student.rating)}
                          </p>
                        </div>

                        {job?.student_type === StudentTypes.EntryLevel && (
                          <Chip
                            sx={{
                              maxWidth: '100px',
                              height: '24px',
                              border: '1.5px solid #1976D2',
                              backgroundColor: 'transparent',
                              color: '#1976D2',
                              fontFamily: "'Sofia W05 Medium', sans-serif",
                            }}
                            label="Entry-level"
                            className="student-level-chip"
                          />
                        )}
                      </div>
                      {proposal?.match_score && (
                        <div style={{ marginTop: '20px' }}>
                          <Tooltip
                            title="How well the student's profile fits with the job. This score is based on multiple data points using AI."
                            placement="left"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: '5px',
                            }}
                          >
                            <div className="">
                              <CircularProgressWithLabel
                                value={proposal.match_score}
                                z-index="999"
                              />
                              <InfoOutlinedIcon
                                fontSize="small"
                                color="disabled"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {proposal?.student.top_talent && (
                  <TopTalent
                    styles={{
                      width: '50px',
                      position: 'relative',
                      top: '-15px',
                    }}
                  />
                )}
              </div>
            </div>
            <p className="student-one-liner">{proposal?.student.one_liner}</p>

            <div className="student-languages">
              <p className="language-list">
                <span className="label">{t('sign_up_student.lang')}: </span>
                <span className="value">
                  {proposal?.student.languages
                    .map((item) => item.language_name)
                    .join(', ')}
                </span>
              </p>
            </div>

            <div className="student-skills">
              {proposal?.student.skills.map((skill) => (
                <Chip
                  sx={{
                    width: 'auto',
                    height: 32,
                    backgroundColor: '#F2F9FC',
                    color: '#393A42',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    fontFamily: 'Sofia W05 Medium, sans-serif',
                  }}
                  key={skill.skill_uid}
                  label={skill.skill_name}
                />
              ))}
            </div>

            <div className="job-header-buttons">
              <Button
                onClick={handleToggleTalentPoolClick}
                className="button medium-text"
                data-testid={`${
                  existInTalentPool ? 'remove_from_tp' : 'add_to'
                }-talent-pool`}
              >
                {`${
                  existInTalentPool
                    ? t('proposal.remove_from_tp')
                    : t('proposal.add_to')
                } talent pool`}
              </Button>
              <Button
                className="button medium-text"
                onClick={handleViewProfile}
                data-testid="view-profile"
              >
                {job?.direct_job
                  ? t('proposal.view_full_profile')
                  : t('proposal.view_profile')}
              </Button>
            </div>
          </div>
          <div className="proposal-info-container">
            <p className="title">Proposal</p>

            <div className="info-rows">
              <p className="info-row">
                <span className="label">Status</span>

                <p className="value">
                  <ProposalStatusChip
                    proposalStatus={proposal?.proposal_status}
                  />
                </p>
              </p>

              {!job.direct_job && (
                <>
                  {+job?.budget < +proposal?.rate && (
                    <>
                      <Alert severity="info" className="counter-offer-alert">
                        <AlertTitle className="counteroffer-title">
                          Counteroffer: €{proposal?.rate}
                        </AlertTitle>
                        <span className="counteroffer-description">
                          {proposal?.counter_offer_description}
                        </span>
                      </Alert>
                    </>
                  )}

                  {job.job_type === JobTypes.ONGOING && (
                    <p className="info-row">
                      <span className="label">
                        {t('proposal.min_availability')}
                      </span>
                      <span className="value">{proposal?.min_month}</span>
                    </p>
                  )}
                </>
              )}
            </div>

            {!job.direct_job && (
              <>
                <div className="accordions-container">
                  <Accordion
                    disableGutters
                    elevation={0}
                    sx={{
                      '&:before': {
                        backgroundColor: 'transparent',
                      },
                      backgroundColor: 'transparent',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="question-accordion"
                    >
                      <Typography className="proposal-question">
                        {job?.question_required}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="proposal-answer">
                        {proposal?.how_you_do_x}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  {job?.question_optional && (
                    <Accordion
                      disableGutters
                      elevation={0}
                      sx={{
                        '&:before': {
                          backgroundColor: 'transparent',
                        },
                        backgroundColor: 'transparent',
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className="question-accordion"
                      >
                        <Typography className="proposal-question">
                          {job?.question_optional}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="proposal-answer">
                          {proposal?.how_you_do_y}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              </>
            )}

            {!job.direct_job &&
              (proposal?.proposal_status === ProposalStatuses.SIGNED ||
                proposal?.proposal_status === ProposalStatuses.ACCEPTED) && (
                <div className="proposal-action-container">
                  <p className="title">{t('dashboard.actions')}</p>

                  <div className="action-list">
                    <Stack
                      direction="column"
                      spacing={1}
                      sx={{ alignItems: 'flex-start' }}
                    >
                      {!proposal?.legacy_id && (
                        <IconButton
                          aria-label="agreement"
                          sx={{ borderRadius: 0 }}
                          onClick={handleAgreementNavigate}
                        >
                          <ReceiptIcon />
                          <span className="action-label">
                            {proposal?.proposal_status ===
                              ProposalStatuses.SIGNED ||
                            proposal?.signed_by_company_at
                              ? 'View'
                              : 'Sign'}{' '}
                            agreement
                          </span>
                        </IconButton>
                      )}
                      {proposal?.proposal_status === ProposalStatuses.SIGNED &&
                        job.job_type === JobTypes.ONGOING && (
                          <IconButton
                            onClick={handleTimesheetNavigate}
                            aria-label="log"
                            color="primary"
                            sx={{ borderRadius: 0 }}
                          >
                            <QueryBuilderOutlinedIcon />
                            <span className="action-label">
                              {t('proposal.view_timesheet')}
                            </span>
                          </IconButton>
                        )}
                    </Stack>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="proposal-company-footer">
          <div className="drawer-buttons">
            <div className="proposal-actions">
              {job?.direct_job_type !== 'Inhouse day' &&
                job?.direct_job_type !== 'Event' && (
                  <>
                    {((job?.job_type !== JobTypes.ONGOING &&
                      (proposal?.proposal_status ===
                        ProposalStatuses.ACCEPTED ||
                        proposal?.proposal_status ===
                          ProposalStatuses.PENDING ||
                        proposal?.proposal_status ===
                          ProposalStatuses.INTERVIEWING)) ||
                      proposal?.proposal_status ===
                        ProposalStatuses.SIGNED) && (
                      <Tooltip
                        disableHoverListener={!maxInterviewsReached}
                        title="You can chat with 2 High-Flyers at once per job opening. If you want to interview more, you need to reject another High-Flyer first that currently has a chat open."
                      >
                        <LoadingButton
                          className={'button chat-button'}
                          confirmButtonTestId={'open-chat'}
                          onClick={handleOpenChat}
                          buttonText={'Chat'}
                          isLoading={startChatLoading}
                        />
                      </Tooltip>
                    )}

                    {job?.job_type !== JobTypes.ONE_TIME &&
                      proposal?.proposal_status ===
                        ProposalStatuses.PENDING && (
                        <Tooltip
                          disableHoverListener={!maxInterviewsReached}
                          title="You can interview 2 High-Flyers at once per job opening. If you want to interview more, you need to reject a previously interviewed High-Flyer first."
                        >
                          <Button
                            disabled={isInviteProposalLoading}
                            className={`button ${
                              maxInterviewsReached ? 'max-reached' : 'accept'
                            }`}
                            data-testid={`${
                              maxInterviewsReached ? 'max-reached' : 'accept'
                            }-interview`}
                            onClick={
                              maxInterviewsReached
                                ? undefined
                                : handleInterviewConfirmationDialogOpen
                            }
                          >
                            {maxInterviewsReached
                              ? 'Max interviews reached'
                              : t('proposal.invite')}
                          </Button>
                        </Tooltip>
                      )}

                    {((proposal?.proposal_status === ProposalStatuses.PENDING &&
                      (job?.job_type !== JobTypes.ONGOING ||
                        (job?.job_type === JobTypes.ONGOING &&
                          maxInterviewsReached))) ||
                      proposal?.proposal_status ===
                        ProposalStatuses.INTERVIEWING) && (
                      // <Button
                      //   disabled={maxHiresReached}
                      //   className={`button hire`}
                      //   onClick={
                      //     job?.direct_job
                      //       ? handleHireStudentForDirectJob
                      //       : handleHireStudentDialogOpen
                      //   }
                      //   data-testid="hire-student"
                      //   // isLoading={isHireLoading}
                      // >
                      <LoadingButton
                        isDisabled={maxHiresReached}
                        className={`button hire`}
                        confirmButtonTestId={'open-chat'}
                        onClick={
                          job?.direct_job
                            ? handleHireStudentForDirectJob
                            : handleHireStudentDialogOpen
                        }
                        data-testid="hire-student"
                        buttonText={'Hire'}
                        isLoading={startChatLoading}
                      />
                      // {t('proposal.hire')}
                      // </Button>
                    )}
                  </>
                )}

              {job?.direct_job &&
                proposal?.proposal_status === ProposalStatuses.PENDING &&
                (job?.direct_job_type === 'Inhouse day' ||
                  job?.direct_job_type === 'Event') && (
                  <LoadingButton
                    isDisabled={maxHiresReached}
                    className={`button hire`}
                    confirmButtonTestId={'open-chat'}
                    onClick={
                      job?.direct_job
                        ? handleHireStudentForDirectJob
                        : handleHireStudentDialogOpen
                    }
                    data-testid="hire-student"
                    buttonText={'Accept'}
                    isLoading={startChatLoading}
                  />
                )}

              {(proposal?.proposal_status === ProposalStatuses.PENDING ||
                proposal?.proposal_status ===
                  ProposalStatuses.INTERVIEWING) && (
                <Button
                  className="button proposal-action reject"
                  data-testid="reject-proposal"
                  onClick={handleRejectDialogOpen}
                >
                  {t('proposal.reject')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>

      <ProposalConfirmationModal
        action="reject"
        content={`Are you sure you want to reject this proposal? This move is final.`}
        open={isRejectDialogOpen}
        handleClose={() => setIsRejectDialogOpen(false)}
        handleProposalAction={(reason) => handleRejectProposal(reason)}
        isLoading={isRejectProposalLoading}
        askReason={true}
      />

      <RequestInterviewModal
        open={isInviteDialogOpen}
        jobType={job?.job_type}
        handleModalSubmit={handleInterviewInviteProposal}
        handleClose={() => setIsInviteDialogOpen(false)}
        isLoading={isInviteProposalLoading}
      />

      <StartChatModal
        open={isChatDialogOpen}
        handleModalSubmit={handleStartChatProposal}
        handleClose={() => setIsChatDialogOpen(false)}
        isLoading={isStartChatLoading}
      />

      <HireStudentModal
        open={isHireDialogOpen}
        job={job}
        proposal={proposal}
        handleModalSubmit={(payload) => handleHireStudent(payload)}
        handleClose={() => setIsHireDialogOpen(false)}
        isLoading={isHireLoading}
      />
      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
    </>
  );
};

export default ProposalCompanyView;

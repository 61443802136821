import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  title: string;
  description: string[];
  count: string;
  isSelected: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const PromoButton = ({
  title,
  description,
  count,
  isSelected,
  onClick,
}: ButtonProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'create_job.step_review',
  });

  const disabledStyle = {
    opacity: '0.5',
    border: '2px solid #C7D2E2',
  };

  const selectedStyle = {
    opacity: '1',
    border: '2px solid #DEB900',
  };

  const isPromo = title === 'Boost your job';
  const isOngoing = title === 'Job posting fee';

  return (
    <div
      className="promo-button"
      style={isSelected ? selectedStyle : disabledStyle}
      onClick={onClick}
      data-testid={`promo-pack-${isPromo ? 'boost' : 'free'}`}
    >
      <div className="promo-button-icon-container">
        <img src="/images/bulb.png" alt="Light bulb" />
      </div>
      <div className="promo-button-content">
        <h2 className="promo-button-title">{title}</h2>
        {isPromo && (
          <p className="promo-button-subtitle">
            {t('promo_pack.boost.description.reach')}
            <span className="bold-text">6x </span>
            {t('promo_pack.boost.description.reach_student')}
          </p>
        )}
        {isOngoing && (
          <p className="promo-button-subtitle">
            We make sure you get seen by 22k candidates.
          </p>
        )}
        <ul className="promo-button-description">
          {description.map((str, key) => {
            return (
              <li key={key}>
                {title === 'Boost your job' ? (
                  <CheckIcon
                    style={{
                      fontSize: '16px',
                      color: '#14DB87',
                      position: 'relative',
                      top: '3px',
                      marginRight: '2px',
                    }}
                  />
                ) : (
                  ''
                )}
                {str}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="promo-count">
        <span className="promo-count-text">{count}</span>
      </div>
    </div>
  );
};

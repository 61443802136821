import {
  Alert,
  AlertTitle,
  Backdrop,
  Breadcrumbs,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link as MuiLink,
  Skeleton,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import React, { useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import './StudentJobDetails.scss';
import {
  JobStatuses,
  JobTypes,
  JobTypesFullName,
  ProposalStatus,
  StudentTypes,
  useAddToFavorites,
  useCompleteJob,
  useFetchFavorites,
  useFetchJobById,
  useRemoveFromFavorites,
} from '../../data/jobs';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { COMPANY_AVATAR_BUCKET, Routes } from '../../utils/consts';
import { formatDate } from '../../utils/dates';
import { JobDrawer } from '../../components/job-drawer';
import MissingInformationModal from './components/MissingInformationModal';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useFetchCurrentUser, UserData } from '../../data/user';
import StudentDrawerContent from './components/CreateProposalDrawer';
import { budgetString, ProposalStatuses } from './index';
import {
  fetchChatByProposalId,
  useCreateProposal,
  useFetchProposalById,
  useFetchStudentProposals,
  useFinishUpdateProposal,
} from '../../data/proposals';
import ProposalStudentView from './components/ProposalStudentView';
import { useTranslation } from 'react-i18next';
import { useFetchIndustries } from '../../data/edit-profile';
import ReviewModal from '../../components/review-modal/ReviewModal';
import { useSetReviewJob } from '../../data/timesheets';
import ReviewsModal from '../../components/reviews-modal/ReviewsModal';
import { numberWithDec } from '../../utils/numberWithDec';
import JobStatusChip from './components/JobStatusChip';
import CompanyAvatar from '../../components/company-avatar';
import SkeletonProposalModal from './SkeletonProposalModal';
import { fetchReviewsByUserType } from '../../data/reviews';
import { openChatUrl } from '../../utils/openChat';
import { useUserData } from '../../stores/auth';
import GenericModal from '../../components/generic-modal/GenericModal';
import { LoadingButton } from '../../components/LoadingButton';
import MiniteSnackbar from '../../components/snackbar';
import {
  blockAccessUnverified,
  UnverifiedModal,
} from '../../components/unverified-modal/UnverifiedModal';
import { Routes as NavigationRoutes } from '../../utils/consts';
import { VerificationRequirements } from '../../routes';
import CircularProgressWithLabel from '../../components/talents/CircularProgressWithLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelReasonModal from './components/CancelReasonModal';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import MiniteGrid from '../../components/grid';
import { handleGridHeight } from '../../utils/helpers';

const StudentJobDetails = () => {
  const { t } = useTranslation();

  const proposalButtonTexts = {
    [ProposalStatuses.DRAFT]: 'Finish draft',
    [ProposalStatuses.PENDING]: 'proposal.view_proposal',
    [ProposalStatuses.INTERVIEWING]: 'proposal.view_proposal',
    [ProposalStatuses.ACCEPTED]: 'Sign agreement',
    [ProposalStatuses.SIGNED]: 'proposal.view_agreement',
    [ProposalStatuses.REJECTED]: 'Rejected',
    [ProposalStatuses.RETRACTED]: 'Retracted',
  };
  const params = useParams<{ jobUid?: string }>();
  const userData = useUserData((state) => state.user);
  // const user: UserData = userData as unknown as UserData;
  const { data: favorites = [], refetch: refetchFavorites } =
    useFetchFavorites();
  const { mutateAsync: addToFavourites } = useAddToFavorites();
  const { data: industries = [] } = useFetchIndustries();
  const { mutateAsync: removeFromFavourites } = useRemoveFromFavorites();
  const { mutateAsync: createProposal, isLoading: isLoadingCreateProposal } =
    useCreateProposal();
  const { mutateAsync: setReviewJob, isLoading: isReviewLoading } =
    useSetReviewJob();
  const {
    data: proposals = [],
    refetch: refetchProposals,
    isLoading: isLoadingProposals,
  } = useFetchStudentProposals(true);
  const proposalByJobId = proposals.find(
    (item) => item.job_uid === params.jobUid,
  );
  const { data: proposalData, refetch: refetchProposal } = useFetchProposalById(
    proposalByJobId?.proposal_uid,
  );
  const {
    data: jobData,
    isLoading,
    refetch: reloadJob,
  } = useFetchJobById(params.jobUid);
  const [industryName, setIndustryName] = useState('');

  const proposal = proposalData?.proposal;
  const [token, setToken] = useState(null);
  const { refetch: reloadUser } = useFetchCurrentUser(token);
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const [successModal, showModal] = useState(false);
  const [directJobModal, showDirectJobModal] = useState(false);
  const [shownDrawer, showDrawer] = useState(false);
  const [shownMissingInfo, showMissingInfo] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [ratingValue, setRatingValue] = useState(null);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [isCompanyOpenChat, setIsCompanyOpenChat] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [jobStartDate, setJobStartDate] = useState(null);
  const [consentModalShown, setConsentModalShown] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [directApplicationLoading, setDirectApplicationLoading] =
    useState(false);

  const { mutateAsync: finishUpdateProposal } = useFinishUpdateProposal();

  const [disableInterestedButton, setDisableInterestedButton] = useState(false);

  const [isEndJob, setIsEndJob] = useState(false);
  const [endJobModalShown, setEndJobModalShown] = useState(false);
  const [isShowToast, setIsShowToast] = useState(false);
  const [isCancelReasonModalShown, setIsCancelReasonModalShown] =
    useState(false);
  const [cancelReasonState, setCancelReasonState] = useState({
    cancel: () => {
      console.log('Cancel called without initializing');
    },
    confirm: (reason: string, endDate: string) => {
      console.log('Confirm called without initializing, reason: ' + reason);
      console.log('Confirm called without initializing, end date: ' + endDate);
    },
    title: t('job_details.cancel_job_reason_modal.title_cancel'),
    description: t('job_details.cancel_job_reason_modal.description_end'),
  });
  const { mutateAsync: completeJob, isLoading: isLoadingCompleteJob } =
    useCompleteJob();

  const checkInterestedButtonShouldBeDisabled = () => {
    console.log(proposal?.proposal_status);
    setDisableInterestedButton(
      (proposal?.proposal_uid &&
        proposal?.proposal_status !== ProposalStatuses.DRAFT) ||
        proposal?.proposal_status === ProposalStatuses.PENDING,
    );
  };

  useEffect(() => {
    if (jobData) {
      if (jobData?.company?.avatar) {
        setAvatar(COMPANY_AVATAR_BUCKET + `${jobData?.company?.avatar}`);
      }

      if (jobData.job_start_at) {
        const startDate = new Date(jobData.job_start_at);
        const now = new Date();

        if (now.getTime() < startDate.getTime()) {
          setJobStartDate(formatDate(jobData.job_start_at, 'DD MMMM YYYY'));
        } else {
          setJobStartDate('ASAP');
        }
      } else {
        setJobStartDate('-');
      }

      setIndustryName(
        industries?.find(
          (item) => item.interest_uid === jobData?.company?.interest_uid,
        )?.interest_name,
      );

      if (proposal?.proposal_status && jobData.job_status) {
        setIsRatingOpen(
          jobData.job_status === 'completed' &&
            !jobData.reviewed &&
            proposal.proposal_status !== ProposalStatuses.REJECTED &&
            proposal.proposal_status !== ProposalStatuses.RETRACTED,
        );
      }
    }
  }, [jobData, proposal]);

  useEffect(() => {
    if (proposal?.proposal_uid) {
      checkInterestedButtonShouldBeDisabled();
      fetchChatByProposalId(proposal.proposal_uid).then(() =>
        setIsCompanyOpenChat(true),
      );
      if (
        jobData?.direct_job &&
        proposal?.proposal_status === ProposalStatuses.DRAFT
      ) {
        finishUpdateProposal({
          proposalId: proposal?.proposal_uid,
          data: { how_you_do_x: '' },
        })
          .then(
            async (res) => {
              console.log(res);
              await refetchProposal();
              await reloadJob();
            },
            async (err) => {
              console.log(err);
              await refetchProposals();
              await reloadJob();
            },
          )
          .finally(() => {
            setDirectApplicationLoading(false);
            showDirectJobModal(true);
          });
      }
    }
  }, [proposal]);

  useEffect(() => {
    if (jobData) {
      fetchReviewsByUserType(jobData.company.company_uid, 'company').then(
        (res) => {
          setReviews(res.reviews);
        },
      );
    }

    setIsEndJob(
      jobData?.job_status === JobStatuses.IN_PROGRESS &&
        !!jobData?.requested_to_complete_at,
    );
  }, [jobData]);

  const showMissingInfoModal = (value) => {
    showMissingInfo(value);
    setToken(userData['token']);
    reloadUser();
  };
  const isUserInfoMissed = (user: UserData): boolean => {
    const requiredAttributes = [
      'house_number',
      'postal_code',
      'street_name',
      'city',
      'iban',
    ];
    const attributesMissing = requiredAttributes.filter(
      (attr) =>
        user && (!user[attr] || user[attr].toString().trim().length === 0),
    );

    return attributesMissing.length > 0;
  };

  const favorite = favorites?.find((fav) => fav.job_uid === jobData?.job_uid);

  const [favoritesLoading, setFavoritesLoading] = useState(false);
  const handleFavouritesClick = async (event) => {
    event?.preventDefault();
    try {
      setFavoritesLoading(true);
      if (favorite) {
        await removeFromFavourites(favorite.favorite_uid);
      } else if (jobData) {
        await addToFavourites(jobData.job_uid);
      }
      await refetchFavorites();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error?.message?.toString(),
      );
    } finally {
      setFavoritesLoading(false);
    }
  };

  const handleProposalDrawerOpen = () => {
    setIsTermsModalOpen(false);

    if (isUserInfoMissed(userData as unknown as UserData)) {
      showMissingInfo(true);
    } else {
      if (proposalByJobId) {
        showDrawer(true);
      } else {
        showDrawer(true);
        createProposal({ jobUid: jobData.job_uid, data: {} }).then(
          () => {
            refetchProposals();
            reloadJob();
          },
          () => {
            showDrawer(false);
            reloadJob();
          },
        );
      }
    }
  };

  const handleInterested = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (jobData.job_title.includes("Women's Connect")) {
      window.open(
        'https://jobs.kpn.com/en/event/12/womens-connect-kpn',
        '_blank',
      );
    } else if (jobData.job_title.includes('HerFuture Is Bright')) {
      window.open(
        'https://www.eventbrite.nl/e/herfuture-is-bright-shaking-up-the-tech-world-tickets-884199082257?aff=minite',
        '_blank',
      );
    } else if (jobData.job_title.includes('In-house on 30th September')) {
      window.open('https://tinyurl.com/WomeninTechVLK', '_blank');
    } else if (jobData.job_title.includes('Lunch with Role Models')) {
      window.open(
        'https://app.herfuture.nl/events/799a14cc-272f-47a4-9b0a-9ee9663cc147',
        '_blank',
      );
    } else {
      setDisableInterestedButton(true);
      setConsentModalShown(true);
    }
  };

  // Only happens with direct job
  const afterConsent = () => {
    setConsentModalShown(false);
    setDirectApplicationLoading(true);
    createProposal({
      jobUid: jobData.job_uid,
      data: { proposal_status: ProposalStatuses.PENDING },
    })
      .then(
        async () => {
          await refetchProposals();
          await reloadJob();
        },
        () => {
          reloadJob();
          setDirectApplicationLoading(false);
        },
      )
      .finally(() => {
        checkInterestedButtonShouldBeDisabled();
      });
  };

  const handleProposalDrawerClose = async () => {
    await refetchProposal();
    await reloadJob();
    showDrawer(false);
  };

  if (isLoading || jobData === undefined) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

        <Skeleton variant="circular" width={80} height={80} />
        <Skeleton variant="rectangular" width={400} height={60} />
        <Skeleton variant="rounded" width={400} height={60} />
      </Stack>
    );
  }

  const navigateToBrowseJobs = () => {
    navigate(Routes.BrowseJobsRoute);
  };

  const handleApply = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsTermsModalOpen(true);
  };

  const submitRating = (review) => {
    setReviewJob({
      description: review,
      rating: ratingValue,
      job_uid: jobData.job_uid,
    }).then(() => {
      reloadJob();
    });
  };

  const handleShowReviewModal = () => {
    setIsOpenReviewModal(!isOpenReviewModal);
  };

  const getApplyButtonText = (proposalStatus = '') => {
    if (!proposalStatus || !proposalButtonTexts[proposalStatus])
      return t('job_details.apply_to_job');

    if (
      (proposal?.legacy_id &&
        proposal?.proposal_status === ProposalStatuses.SIGNED) ||
      (!proposal?.legacy_id && !proposal.signed_by_company_at)
    ) {
      return t(proposalButtonTexts[ProposalStatuses.PENDING]);
    }

    return t(proposalButtonTexts[proposalStatus]);
  };

  const handleChatNavigate = () => {
    openChatUrl(proposal?.proposal_uid);
  };

  const handleNavigateToJobs = () => {
    navigate(Routes.BrowseJobsRoute);
  };

  const subCategory = jobData.sub_category.code
    ? t(`sub_categories.${jobData.sub_category.code}`)
    : jobData.sub_category.sub_category_name || '';

  const ChatButton = () => {
    const { proposal_status } = proposal || {};
    const { job_status } = jobData || {};

    const propStatus = {
      [ProposalStatuses.ACCEPTED]: ProposalStatuses.ACCEPTED,
      [ProposalStatuses.INTERVIEWING]: ProposalStatuses.INTERVIEWING,
      [ProposalStatuses.SIGNED]: ProposalStatuses.SIGNED,
    };

    const jobStatus = {
      [JobStatuses.IN_PROGRESS]: JobStatuses.IN_PROGRESS,
      [JobStatuses.SIGNED]: JobStatuses.SIGNED,
      [JobStatuses.INTERVIEWING]: JobStatuses.INTERVIEWING,
    };

    return (
      (isCompanyOpenChat ||
        (propStatus[proposal_status] && jobStatus[job_status])) &&
      (isMobileView ? (
        <Button
          variant="contained"
          className="confirm-button apply-button"
          onClick={handleChatNavigate}
          data-testid="open-chat"
        >
          Chat
        </Button>
      ) : (
        <Button
          onClick={handleChatNavigate}
          data-testid="open-chat"
          startIcon={<ChatBubbleOutlineOutlinedIcon />}
          className="medium-text"
        >
          <span className="action-label no-transform">Chat</span>
        </Button>
      ))
    );
  };

  const handleTimesheetNavigate = () => {
    const path = generatePath(Routes.TimesheetRoute, {
      jobUid: jobData?.job_uid,
    });
    navigate(path, {
      replace: true,
    });
  };

  const disableApplyButton =
    // (user?.student?.student_type === StudentTypes.EntryLevel &&
    //   jobData?.student_type === StudentTypes.Regular) ||
    proposal?.proposal_status === ProposalStatuses.RETRACTED ||
    (!proposal?.proposal_uid &&
      jobData?.job_status !== JobStatuses.OPEN &&
      jobData?.job_status !== JobStatuses.INTERVIEWING);

  const handleUnverifiedModalConfirm = () => {
    return navigate(NavigationRoutes.ProfileSettingsRoute, { replace: true });
  };

  if (
    !jobData?.direct_job &&
    !userData?.verified &&
    blockAccessUnverified(VerificationRequirements.Student, userData)
  ) {
    return (
      <UnverifiedModal
        cancelAction={navigateToBrowseJobs}
        confirmAction={handleUnverifiedModalConfirm}
      />
    );
  }

  const canEndJob =
    jobData.job_status === JobStatuses.IN_PROGRESS &&
    JobTypes.ONGOING === jobData.job_type;

  const handleOpenEndJobModal = () => {
    setEndJobModalShown(!endJobModalShown);
  };

  const showEndJobDate = (title, description, confirmAction, cancelAction?) => {
    setIsCancelReasonModalShown(true);
    setCancelReasonState({
      confirm: (reason, endDate) => {
        setIsCancelReasonModalShown(false);
        confirmAction(reason, endDate);
      },
      cancel: () => {
        setIsCancelReasonModalShown(false);
        if (cancelAction) {
          cancelAction();
        }
      },
      title,
      description,
    });
  };

  const sendEndJobRequest = (reason: string, endDate: string) => {
    completeJob({
      jobUid: jobData.job_uid,
      reason: reason,
      endDate: endDate,
    }).then(() => {
      handleOpenEndJobModal();
      setIsShowToast(true);
      setIsEndJob(true);
      reloadJob();
    });
  };

  const invoiceColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'invoice_number',
      headerName: 'Invoice number',
      width: 200,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      width: 200,
    },
    {
      field: 'paid_status',
      headerName: 'Paid status',
      width: 200,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <>
          {params.value ? (
            <DoneIcon sx={{ color: '#14DB87' }} />
          ) : (
            <CloseIcon sx={{ color: '#EA4335' }} />
          )}
        </>
      ),
    },
  ];

  const invoicesGridData = (): unknown[] => {
    const onetimeInvoices =
      jobData.invoices &&
      jobData.invoices
        ?.filter((invoice) => invoice.invoice_number !== null)
        ?.filter((invoice) => invoice.invoice_number[0] === 'V')
        ?.map((invoice) => {
          return {
            id: invoice.invoice_uid,
            invoice_number: invoice.invoice_number,
            created_at: formatDate(invoice.created_at, 'DD/MM/YYYY'),
            paid_status: invoice.invoice_paid,
            student_uid: null,
          };
        });
    const timesheetInvoices =
      jobData.timesheets &&
      jobData.timesheets
        ?.filter((t) => t.invoice_sent)
        ?.map((t) => {
          return {
            id: t.timesheet_uid,
            invoice_number: t.student_collective_invoice_number,
            created_at: formatDate(t.created_at, 'DD/MM/YYYY'),
            paid_status: t.invoice_paid,
            student_uid: t.student_uid,
          };
        });
    if (onetimeInvoices && timesheetInvoices) {
      return timesheetInvoices.concat(onetimeInvoices);
    } else if (onetimeInvoices) {
      return onetimeInvoices;
    } else {
      return timesheetInvoices;
    }
  };

  return (
    <>
      <Grid container className="job-details-student-view-container">
        <Grid item xs={12} md={8}>
          <div className="job-container">
            <Breadcrumbs>
              <MuiLink onClick={navigateToBrowseJobs}>
                {t('header.browse_jobs')}
              </MuiLink>
              <p>{jobData.job_title}</p>
            </Breadcrumbs>

            <h1 className="title">
              {jobData.direct_job
                ? jobData.direct_job_type === 'Inhouse day' ||
                  jobData.direct_job_type === 'Event'
                  ? 'Event details'
                  : t('job_details.job_details')
                : t('job_details.job_details')}
            </h1>

            <div className="job-details">
              <div className="brief-info">
                <div className="top">
                  <div className="left">
                    <div style={{ display: 'flex' }}>
                      <CompanyAvatar avatar={avatar} />
                      <div className="titles-container">
                        <h3 className="title">{jobData.job_title}</h3>
                        <div className="company-detail">
                          <div className="rate">
                            <StarIcon className="favourite-rate-star" />
                            <span className="rating">
                              {numberWithDec(jobData.company.rating)}
                            </span>
                            <ButtonBase
                              className="review-count"
                              onClick={handleShowReviewModal}
                              data-testid="open-reviews"
                            >
                              {reviews.length} reviews
                            </ButtonBase>
                            <ReviewsModal
                              reviews={reviews}
                              isOpen={isOpenReviewModal}
                              onClose={handleShowReviewModal}
                            />
                          </div>

                          <p className="company-label">
                            {jobData.company.company_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {jobData?.match_score > 0 && (
                        <Tooltip
                          title="How well your profile fits with the job. This score is based on multiple data points using AI."
                          placement="right-start"
                          style={{
                            display: 'flex',
                            marginTop: '10px',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div className="match-score-container">
                            <CircularProgressWithLabel
                              value={jobData.match_score}
                            />
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="right">
                    <div className="job-chip-wrapper">
                      <JobStatusChip jobStatus={jobData.job_status} />
                    </div>
                    <p className="date medium-text">
                      {jobData.job_status !== JobStatuses.DRAFT &&
                        jobData.job_status !== JobStatuses.PENDING_APPROVAL &&
                        !jobData.direct_job &&
                        (formatDate(jobData?.open_at, 'DD MMMM YYYY') || '')}
                    </p>
                    {!jobData.direct_job && (
                      <>
                        {jobData.job_type === JobTypes.ONGOING &&
                          jobData.student_type === StudentTypes.EntryLevel && (
                            <Chip
                              className="medium-text top-chip"
                              label="Entry-level"
                              variant="outlined"
                              color="primary"
                              sx={{
                                fontSize: '12px',
                                lineHeight: '20px',
                                width: 'auto',
                                height: 24,
                                backgroundColor: '#ffffff',
                                borderColor: '#1976D2',
                                color: '#1976D2',
                                fontWeight: 500,
                                fontFamily: 'Sofia W05 Medium, sans-serif',
                              }}
                            />
                          )}
                        <Chip
                          className="medium-text"
                          label={`Hiring ${jobData.students_needed} High-Flyers`}
                          variant="outlined"
                          color="warning"
                          sx={{
                            fontSize: '12px',
                            lineHeight: '20px',
                            width: 'auto',
                            height: 24,
                            backgroundColor: '#ffffff',
                            borderColor: '#DEB900',
                            color: '#46566E',
                            fontWeight: 500,
                            fontFamily: 'Sofia W05 Medium, sans-serif',
                          }}
                        />
                      </>
                    )}
                    {jobData.direct_job && (
                      <>
                        <Chip
                          className="medium-text top-chip"
                          label={jobData.direct_job_type}
                          variant="outlined"
                          color="primary"
                          sx={{
                            fontSize: '12px',
                            lineHeight: '20px',
                            width: 'auto',
                            height: 24,
                            backgroundColor: '#ffffff',
                            borderColor: '#13bd73',
                            color: '#13bd73',
                            fontWeight: 500,
                            fontFamily: 'Sofia W05 Medium, sans-serif',
                          }}
                        />
                      </>
                    )}
                    {jobData?.is_hot &&
                      !disableApplyButton &&
                      proposal?.proposal_status !== 'rejected' && (
                        <Chip
                          sx={{
                            width: 'auto',
                            padding: '6px 6px 7px',
                            border: '1.5px solid #ff5a00',
                            backgroundColor: 'transparent',
                            color: '#ff5a00',
                            fontFamily: 'Sofia W05 Medium, sans-serif',
                            marginTop: '5px',
                          }}
                          label={'🔥 Hot Job - High chance of landing it 🔥'}
                          className="student-level-chip"
                        />
                      )}
                  </div>
                </div>

                <div className="bottom">
                  <div className="actions">
                    <LoadingButton
                      startIcon={
                        favorite ? (
                          <BookmarkRemoveOutlinedIcon />
                        ) : (
                          <BookmarkAddOutlinedIcon />
                        )
                      }
                      variant="text"
                      onClick={handleFavouritesClick}
                      // isDisabled={
                      //   user?.student?.student_type ===
                      //     StudentTypes.EntryLevel &&
                      //   jobData?.student_type === StudentTypes.Regular
                      // }
                      isLoading={favoritesLoading}
                      className="medium-text"
                      confirmButtonTestId={`${
                        favorite ? 'remove' : 'add'
                      }-favorite`}
                      buttonText={
                        favorite
                          ? t('job_details.remove_favourite')
                          : t('job_details.add_to_fav')
                      }
                    />
                    {!isMobileView && jobData.direct_job ? (
                      <Button
                        className="button medium-text"
                        onClick={handleInterested}
                        data-testid="interested"
                        disabled={disableInterestedButton}
                      >
                        {jobData.direct_job_type === 'Inhouse day' ||
                        jobData.direct_job_type === 'Event'
                          ? t('proposal.join_event')
                          : t('proposal.apply_one_click')}
                      </Button>
                    ) : (
                      <Button
                        className="button medium-text"
                        onClick={handleApply}
                        data-testid="open-proposal-modal"
                        disabled={disableApplyButton}
                      >
                        {getApplyButtonText(proposal?.proposal_status)}
                      </Button>
                    )}
                  </div>

                  {!isMobileView &&
                    !(
                      jobData.direct_job &&
                      (jobData.direct_job_type === 'Inhouse day' ||
                        jobData.direct_job_type === 'Event')
                    ) && (
                      <p className="applied medium-text">
                        {t('job_details.high_flyers_applied')}{' '}
                        {jobData.proposals_count}
                      </p>
                    )}
                </div>
              </div>

              {isMobileView && (
                <div className="confirm-button-mobile-container">
                  {jobData.direct_job ? (
                    <Button
                      variant="contained"
                      className="confirm-button apply-button confirm-mobile-button"
                      onClick={handleInterested}
                      disabled={disableInterestedButton}
                      data-testid="interested"
                    >
                      {jobData.direct_job_type === 'Inhouse day' ||
                      jobData.direct_job_type === 'Event'
                        ? t('proposal.join_event')
                        : t('proposal.apply_one_click')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="confirm-button apply-button confirm-mobile-button"
                      onClick={handleApply}
                      disabled={disableApplyButton}
                      data-testid="open-proposal-modal"
                    >
                      {getApplyButtonText(proposal?.proposal_status)}
                    </Button>
                  )}
                  <ChatButton />
                  {proposal?.proposal_status === ProposalStatuses.SIGNED &&
                    jobData?.job_type === JobTypes.ONGOING &&
                    jobData?.job_status === JobStatuses.IN_PROGRESS && (
                      <Button
                        variant="contained"
                        aria-label="log"
                        className="confirm-button apply-button confirm-mobile-button"
                        onClick={handleTimesheetNavigate}
                        disabled={disableApplyButton}
                        data-testid="open-timesheet"
                      >
                        {t('proposal.log_hours')}
                      </Button>
                    )}
                </div>
              )}

              <div className="main">
                <div className="top">
                  <h2 className="title heading-text">
                    {jobData.direct_job
                      ? jobData.direct_job_type === 'Inhouse day' ||
                        jobData.direct_job_type === 'Event'
                        ? 'Event'
                        : 'Direct job'
                      : JobTypesFullName[jobData.job_type]}
                  </h2>
                  {jobData.direct_job ? (
                    <h2 className="budget heading-text">
                      {jobData.direct_job_compensation}
                    </h2>
                  ) : (
                    <h2 className="budget heading-text">
                      {budgetString(jobData.budget, jobData.job_type)}
                    </h2>
                  )}
                </div>

                {jobData.direct_job && (
                  <>
                    <div className="info">
                      <div className="left">
                        <p className="info-title medium-text">
                          Job type:{' '}
                          <span className="info-value">
                            {jobData.direct_job_type}
                          </span>
                        </p>
                        {jobData.office_location && (
                          <p className="info-title">
                            {t('Location: ')}:{' '}
                            <span className="info-value location">
                              {jobData.office_location}
                            </span>
                          </p>
                        )}
                        {jobData.job_start_at && (
                          <p className="info-title">
                            {t('Event date')}:{' '}
                            <span className="info-value">
                              {jobData.job_start_at.substring(0, 10)}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    {jobData.direct_job_type !== 'Inhouse day' &&
                      jobData.direct_job_type !== 'Event' && (
                        <div className="direct-job-info">
                          <Alert severity="info">
                            <AlertTitle>Direct job</AlertTitle>
                            This is a job directly with the company, and{' '}
                            <b>not</b> a freelance job. The company will be
                            handling the interviewing and hiring process, and
                            your contract and administration will not be going
                            through the Minite platform.
                          </Alert>
                        </div>
                      )}
                  </>
                )}

                {!jobData.direct_job && (
                  <div className="info">
                    <div className="left">
                      <p className="info-title medium-text">
                        {t('filters.category')}:{' '}
                        <span className="info-value">
                          {t(`categories.${jobData.category.code}`)}
                          {jobData.sub_category &&
                            !!subCategory &&
                            ` | ${subCategory}`}
                        </span>
                      </p>
                      <p className="info-title medium-text">
                        {t('job_details.langs')}:{' '}
                        <span className="info-value">
                          {jobData.languages
                            ?.map((item) => item.language_name)
                            .join(', ')}
                        </span>
                      </p>

                      <p className="info-title medium-text">
                        {jobData?.job_type === JobTypes.ONGOING
                          ? t('job_details.hours_per_week')
                          : t('job_details.estimate_hours_needed')}
                        :{' '}
                        <span className="info-value">
                          {jobData.hours_estimated}
                        </span>
                      </p>

                      <p className="info-title medium-text">Skills:</p>
                      <div className="skills-container medium-text">
                        {jobData.skills?.map((skill) => (
                          <Chip
                            className="medium-text"
                            key={skill.skill_uid}
                            label={skill.skill_name}
                            sx={{
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="right">
                      {isMobileView &&
                        !(
                          jobData.direct_job &&
                          (jobData.direct_job_type === 'Inhouse day' ||
                            jobData.direct_job_type === 'Event')
                        ) && (
                          <p className="info-title">
                            {t('job_details.high_flyers_applied')}{' '}
                            <span className="info-value">
                              {jobData.proposals_count}
                            </span>
                          </p>
                        )}
                      <p className="info-title">
                        {t('job_details.location')}:{' '}
                        <span className="info-value location">
                          {jobData.job_location}
                        </span>
                      </p>
                      {jobData.office_location && (
                        <p className="info-title">
                          {t('job_details.office_location')}:{' '}
                          <span className="info-value location">
                            {jobData.office_location}
                          </span>
                        </p>
                      )}
                      <p className="info-title">
                        {t('job_details.start_date')}:{' '}
                        <span className="info-value">{jobStartDate}</span>
                      </p>
                      {!!jobData?.job_end_at && (
                        <p className="info-title">
                          {t('job_details.end_date')}:{' '}
                          <span className="info-value">
                            {formatDate(jobData.job_end_at, 'DD MMMM YYYY')}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                )}

                <div className="description">
                  <h4 className="title medium-text">
                    {jobData.direct_job
                      ? jobData.direct_job_type === 'Inhouse day' ||
                        jobData.direct_job_type === 'Event'
                        ? 'Event description'
                        : t('job_details.job_desc')
                      : t('job_details.job_desc')}
                  </h4>
                  <p className="text medium-text">{jobData.job_description}</p>
                </div>

                {
                  <>
                    {proposal?.proposal_status === ProposalStatuses.SIGNED &&
                      (jobData?.job_status === JobStatuses.IN_PROGRESS ||
                        jobData?.job_status === JobStatuses.COMPLETED) && (
                        <div className="proposals">
                          <h3 className="heading-text">Invoices</h3>
                          <MiniteGrid
                            height={handleGridHeight(
                              jobData.invoices?.length || 0,
                              8,
                            )}
                            columns={invoiceColumns}
                            rows={invoicesGridData()}
                            rowId={'id'}
                            disableColumnFilter={true}
                          />
                        </div>
                      )}
                  </>
                }
              </div>
            </div>
          </div>
        </Grid>

        {!isMobileView && (
          <Divider
            orientation="vertical"
            flexItem
            className="divider-wrapper"
          />
        )}

        <Grid item xs={12} md={3}>
          {!proposal?.proposal_status ||
          !proposalButtonTexts[proposal?.proposal_status] ? (
            <div className="company-details">
              <div className="details-container">
                <CompanyAvatar avatar={avatar} />

                <div className="titles-container">
                  <h3 className="title medium-text">
                    {jobData.company.company_name}
                  </h3>
                  <div className="rate">
                    <StarIcon className="favourite-rate-star" />
                    <span className="rating">
                      {numberWithDec(jobData.company.rating)}
                    </span>
                    <ButtonBase
                      className="review-count"
                      onClick={handleShowReviewModal}
                      data-testid="show-reviews-modal"
                    >
                      {reviews.length} reviews
                    </ButtonBase>
                    <ReviewsModal
                      reviews={reviews}
                      isOpen={isOpenReviewModal}
                      onClose={handleShowReviewModal}
                    />
                  </div>
                </div>
              </div>

              <div className="info company-info">
                <p>
                  <span className="info-title">
                    {t('job_details.industry')}:
                  </span>{' '}
                  <span className="info-value">{industryName}</span>
                </p>
              </div>

              <div className="info company-address">
                <p>
                  <span className="info-title">
                    {jobData.address.street_name} {jobData.address.house_number}
                    ,
                  </span>
                </p>
                <p>
                  <span className="info-title">
                    {jobData.address.postal_code} {jobData.address.city}
                  </span>
                </p>
              </div>

              <div className="info company-description">
                <p className="info-title">
                  <span>{jobData?.company?.bio}</span>
                </p>
              </div>

              {jobData.direct_job ? (
                <Button
                  variant="contained"
                  className="confirm-button apply-button"
                  onClick={handleInterested}
                  disabled={disableInterestedButton}
                  data-testid="interested"
                >
                  {jobData.direct_job_type === 'Inhouse day' ||
                  jobData.direct_job_type === 'Event'
                    ? t('proposal.join_event')
                    : t('proposal.apply_one_click')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="confirm-button apply-button"
                  onClick={handleApply}
                  disabled={disableApplyButton}
                  data-testid="open-proposal"
                >
                  {getApplyButtonText(proposal?.proposal_status)}
                </Button>
              )}
              <ChatButton />
            </div>
          ) : (
            !isMobileView && (
              <div className="actions-container no-border">
                <h3 className="title heading-text">{t('proposal.actions')}</h3>
                {!proposal?.legacy_id && (
                  <div>
                    {jobData.direct_job ? (
                      <Button
                        onClick={handleInterested}
                        startIcon={<ReceiptIcon />}
                        disabled={disableInterestedButton}
                      >
                        <span className="action-label no-transform medium-text">
                          {jobData.direct_job_type === 'Inhouse day' ||
                          jobData.direct_job_type === 'Event'
                            ? t('proposal.join_event')
                            : t('proposal.apply_one_click')}
                        </span>
                      </Button>
                    ) : (
                      <Button
                        onClick={handleApply}
                        data-testid="open-proposal"
                        startIcon={<ReceiptIcon />}
                      >
                        <span className="action-label no-transform medium-text">
                          {getApplyButtonText(proposal?.proposal_status)}
                        </span>
                      </Button>
                    )}
                  </div>
                )}
                <div>
                  <ChatButton />
                </div>
                {proposal?.proposal_status === ProposalStatuses.SIGNED &&
                  jobData?.job_type === JobTypes.ONGOING &&
                  jobData?.job_status === JobStatuses.IN_PROGRESS && (
                    <div>
                      <Button
                        onClick={handleTimesheetNavigate}
                        data-testid="open-timesheet"
                        startIcon={<QueryBuilderOutlinedIcon />}
                      >
                        <span className="action-label no-transform medium-text">
                          {t('proposal.log_hours')}
                        </span>
                      </Button>
                    </div>
                  )}
                {canEndJob && !isEndJob && (
                  <ButtonBase
                    className="action medium-text"
                    onClick={handleOpenEndJobModal}
                    data-testid="end-job"
                  >
                    <CloseIcon className="icon close" />
                    <p className="close">{t('job_details.end_job')}</p>
                  </ButtonBase>
                )}
                {isEndJob && <p className="close">Job ending soon</p>}
                <>
                  {jobData.in_progress_at && (
                    <>
                      <hr />
                      <p className="close">
                        Started at:{' '}
                        {formatDate(jobData.in_progress_at, 'DD MMMM YYYY')}
                      </p>
                    </>
                  )}
                  {jobData.completed_at && (
                    <>
                      <p className="close">
                        Completed at:{' '}
                        {formatDate(jobData.completed_at, 'DD MMMM YYYY')}
                      </p>
                    </>
                  )}
                </>
              </div>
            )
          )}
        </Grid>
        <JobDrawer
          open={shownDrawer}
          onClose={handleProposalDrawerClose}
          className="job-drawer-student--wrapper"
        >
          {isLoadingCreateProposal || isLoadingProposals ? (
            <SkeletonProposalModal />
          ) : (
            <>
              {!proposal ||
                (proposal?.proposal_status === ProposalStatuses.DRAFT && (
                  <StudentDrawerContent
                    user={userData}
                    job={jobData}
                    proposal={proposal}
                    handleDrawerClose={handleProposalDrawerClose}
                    handleProposalSubmit={() => showModal(true)}
                  />
                ))}
              {proposal?.proposal_status !== ProposalStatuses.DRAFT && (
                <ProposalStudentView
                  user={userData as unknown as UserData}
                  job={jobData}
                  proposal={proposal}
                  handleDrawerClose={handleProposalDrawerClose}
                />
              )}
            </>
          )}
        </JobDrawer>

        <GenericModal
          open={successModal}
          title={t('proposal.one_step_closer')}
          content={
            <>
              {t('proposal.next_steps_0')}
              <br />
              {t('proposal.next_steps_1')}
              <br />
              {t('proposal.next_steps_2')}
              <br />
              {t('proposal.next_steps_3')}
              <br />
              {t('proposal.next_steps_4')}
            </>
          }
          cancelButtonText="Close"
          confirmButtonText="Browse more jobs"
          handleCancel={() => showModal(false)}
          handleConfirm={handleNavigateToJobs}
        />

        <GenericModal
          open={directJobModal}
          title={t('proposal.applied')}
          content={t('proposal.applied_desc')}
          cancelButtonText="Close"
          confirmButtonText="Browse more jobs"
          handleCancel={() => showDirectJobModal(false)}
          handleConfirm={handleNavigateToJobs}
        />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={directApplicationLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <GenericModal
          open={consentModalShown}
          title={
            jobData.direct_job_type === 'Inhouse day' ||
            jobData.direct_job_type === 'Event'
              ? t('proposal.event_consent_modal_title')
              : t('proposal.consent_modal_title')
          }
          content={
            jobData.direct_job_type === 'Inhouse day' ||
            jobData.direct_job_type === 'Event'
              ? t('proposal.event_consent_modal_content')
              : t('proposal.consent_modal_content')
          }
          cancelButtonText={t('proposal.consent_modal_cancel')}
          confirmButtonText={
            jobData.direct_job_type === 'Inhouse day' ||
            jobData.direct_job_type === 'Event'
              ? t('proposal.event_consent_modal_confirm')
              : t('proposal.consent_modal_confirm')
          }
          handleCancel={() => {
            setDisableInterestedButton(false);
            setConsentModalShown(false);
          }}
          handleConfirm={afterConsent}
        />

        <MissingInformationModal
          open={shownMissingInfo}
          handleClose={() => showMissingInfoModal(false)}
        />

        <ReviewModal
          title={t('job_completed.job_completed')}
          content={[
            `Well done on completing this job. How'd it go? Please tell us about your experience with ${jobData.company.company_name}`,
          ]}
          ratingValue={ratingValue}
          handleRatingValue={setRatingValue}
          isOpen={isRatingOpen}
          onClose={() => setIsRatingOpen(false)}
          onSubmit={submitRating}
          isLoading={isReviewLoading}
        />

        <GenericModal
          title={t('job_details.terms_of_use_title')}
          content={
            <span>
              {t('job_details.terms_of_use_begin')}{' '}
              <a
                href="https://www.minite.works/terms-of-use/terms-of-use-students"
                style={{ textDecoration: 'underline' }}
              >
                {t('job_details.terms_of_use_link')}
              </a>
              {'. '}
              {t('job_details.terms_of_use_end')}
            </span>
          }
          open={isTermsModalOpen}
          handleConfirm={handleProposalDrawerOpen}
          handleCancel={() => {
            setIsTermsModalOpen(false);
          }}
          confirmButtonText={t('job_details.terms_of_use_accept')}
          cancelButtonText={t('job_details.terms_of_use_cancel')}
        />

        <GenericModal
          open={endJobModalShown}
          title={t('job_completed.end_ongoing_job')}
          content={t('job_completed.agreement_note')}
          cancelButtonText={t('job_completed.cancel')}
          confirmButtonText={t('job_completed.confirm')}
          handleCancel={handleOpenEndJobModal}
          handleConfirm={() => {
            handleOpenEndJobModal();
            showEndJobDate(
              'Job end details',
              t('job_details.cancel_job_reason_modal.description_end'),
              sendEndJobRequest,
            );
          }}
          isLoading={isLoadingCompleteJob}
          confirmButtonTestId="end-job-confirm"
          loadingButton
        />
        <CancelReasonModal
          open={isCancelReasonModalShown}
          title={cancelReasonState.title}
          description={cancelReasonState.description}
          handleClose={cancelReasonState.cancel}
          handleConfirm={cancelReasonState.confirm}
          jobData={jobData}
        />
        <MiniteSnackbar
          severity={'success'}
          message={'Your request has been received, thank you!'}
          autoHideDuration={4000}
          open={isShowToast}
          onClose={() => setIsShowToast(false)}
        />
      </Grid>
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default StudentJobDetails;
